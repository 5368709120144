<template>
    <div class="container mx-auto py-24">
        <div class="grid grid-cols-2 gap-16">
            <div class="">
                <div class="max-w-lg">
                    <h1 class="font-bold text-6xl text-blue-900 leading-none my-12">
                        {{$t('getStarted.heading')}}
                    </h1>
                    <p v-html="$t('getStarted.intro')" />
                </div>
            </div>

            
            <form 
                v-if="!status"
                @submit.prevent="submitForm()">
                <!-- <select v-model="form.interest">
                    <option :value="null" disabled>
                        {{$t('getStarted.areaOfInterest')}}
                    </option>
                    <option 
                        v-for="(interest, i) in interests" 
                        :key="'interest_'+i" 
                        :value="interest">
                        
                        {{interest}}
                    </option>
                </select> -->
                
                
                <input 
                    v-model="form.firstName"
                    type="text"
                    :placeholder="$t('general.firstName')"
                />
                
                <input 
                    v-model="form.lastName"
                    type="text"
                    :placeholder="$t('general.lastName')"
                />
                
                <input 
                    v-model="form.companyEmail"
                    type="email"
                    :placeholder="$t('general.companyEmail')"
                />
                
                <input 
                    v-model="form.companyName"
                    type="text"
                    :placeholder="$t('general.companyName')"
                />
                
                <input 
                    v-model="form.companyWebsite" 
                    type="text" 
                    :placeholder="$t('general.companyWebsite')" 
                />
                
                <button type="submit" class="bg-yellow-600 px-4 py-3 text-white rounded">
                    {{$t('getStarted.bookADemo')}}
                </button>
            </form>
            <div 
                v-else
                class="py-8 text-left">
                
                <i class="fas fa-thumbs-up text-yellow-600 text-5xl" />
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            // interests: [
            //     'Retail',
            //     'Design and marketing',
            //     'Agency',
            //     'Partnerships',
            //     'Other',
            // ],
            form: {
                // interest: null,
                formType: 'bookADemo',
                firstName: null,
                lastName: null,
                companyEmail: null,
                companyName: null,
                companyWebsite: null,
            },
            status: null,
        }
    },
    
    methods: {
        async submitForm(){
            const response = await this.$axios.post('https://9qqwueeee8.execute-api.eu-west-1.amazonaws.com/prod/form', this.form);
            console.log(response);
            this.status = response;
        }
    },
}
</script>

<style lang="scss" >
</style>
